import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0';
import 'assets/scss/style.css';

import AuthLayout from 'layouts/Auth.jsx';
import RtlLayout from 'layouts/RTL.jsx';
import AdminLayout from 'layouts/Admin.jsx';
import ErrorPage from 'views/Pages/ErrorPage.jsx';
import Dashboard from 'views/Pages/Dashboard.jsx';
import Login from 'views/Pages/LoginPage.jsx';
import EsCatalogs from './views/Tables/EsCatalogs.jsx';
import CatalogsDetail from './views/Pages/CatalogsDetail.jsx';
import Advanced from 'views/Tables/Advanced.jsx';
import Orders from './views/Tables/Orders.jsx';
import OrderDetail from './views/Pages/OrderDetail.jsx';
import SiteLayout from './gridComponents/SiteLayout.jsx';
import BulkPublishDetails from '../src/views/Tables/BulkPublishDetails';
import Configuration from '../src/views/Pages/ConfigurationPage';
import ConfigurationDetails from '../src/views/Pages/ConfigurationDetails';
import BusinessRule from 'views/Tables/BusinessRule.js';
import BulkDeleteStaleProduct from 'views/Tables/BulkDeleteStaleProductDetails.js';
import CreateBasicProduct from 'views/Pages/CeateUpdateProductPage/CreateBasicProductPage.js';
import CreateParentProduct from 'views/Pages/CeateUpdateProductPage/CreateParentProductPage.js';
import CreateMasterProduct from 'views/Pages/CeateUpdateProductPage/CreateMasterProductPage.js';
import EditBasicProduct from 'views/Pages/CeateUpdateProductPage/EditBasicProductPage.js';
import EditParentProduct from 'views/Pages/CeateUpdateProductPage/EditParentProductPage.js';
import EditMasterProduct from 'views/Pages/CeateUpdateProductPage/EditMasterProductPage';
import ProductConfiguration from 'views/Pages/ProductConfiguration';
import ProductExports from 'views/Pages/Exports/ProductExports';
import OrderExports from 'views/Pages/Exports/OrderExports';
import ShipStationStoreList from 'views/Pages/ShipStationStoreList.jsx';
import McSettingList from 'views/Pages/McSetting.jsx';

import { Auth0Provider } from '@auth0/auth0-react';

import Auth0validation from './views/Pages/Auth0validation';

export const hist = createBrowserHistory();
var maintainBreadCrumbs = [];
// function to update maintainBreadCrumbs list with current pages
const maintainBreadCrumbsFunction = obj => {
  maintainBreadCrumbs.push(obj);
  return maintainBreadCrumbs;
};
// function to clear maintainBreadCrumbs
const clearCrumbs = () => {
  maintainBreadCrumbs = [];
};

const domian = 'accounts.stockx.com';
const clientId = 'c77IWg9Qi7gT5TyXNPoRaH4p86hqAK8Z';
const redirectUri = 'https://projec4mc.com/auth0validation';

ReactDOM.render(
  <Auth0Provider
    domain={domian}
    clientId={clientId}
    redirectUri={redirectUri}
    scope="openid profile email offline_access"
  >
    <Router history={hist}>
      <SiteLayout>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/auth/login-page" component={Login} />
          <Route
            path="/auth0validation"
            component={Auth0validation}
          />
          <Route path="/error" component={ErrorPage} />
          {/* <Route exact path="/catalogs" component={Catalogs} /> */}
          <Route exact path="/catalogs" component={EsCatalogs} />
          <Route exact path="/advanced" component={Advanced} />
          <Route
            exact
            path="/catalogs/:id"
            render={route => (
              <CatalogsDetail
                {...route}
                clearCrumbs={clearCrumbs}
                maintainBreadCrumbsFunction={
                  maintainBreadCrumbsFunction
                }
              />
            )}
          />
          {/* <Route
            exact
            path="/catalogs/:id"
            render={route => (
              <CatalogsDetail
                {...route}
                clearCrumbs={clearCrumbs}
                maintainBreadCrumbsFunction={maintainBreadCrumbsFunction}
              />
            )}
          /> */}
          <Route exact path="/orders" component={Orders} />
          <Route
            exact
            path="/orders/:id"
            render={route => (
              <OrderDetail
                {...route}
                clearCrumbs={clearCrumbs}
                maintainBreadCrumbsFunction={
                  maintainBreadCrumbsFunction
                }
              />
            )}
          />
          {/* <Route
            exact
            path="/catalogs/details/:id"
            render={route => (
              <CatalogsDetail
                {...route}
                clearCrumbs={clearCrumbs}
                maintainBreadCrumbsFunction={maintainBreadCrumbsFunction}
              />
            )}
          /> */}
          <Route
            exact
            path="/bulkpublish/:id"
            component={BulkPublishDetails}
          />
          <Route
            exact
            path="/configuration"
            component={Configuration}
          />
          <Route
            exact
            path="/configuration/details/:id"
            component={ConfigurationDetails}
          />
          <Route exact path="/rule" component={BusinessRule} />
          <Route
            exact
            path="/bulk_delete_stale_product/:id"
            component={BulkDeleteStaleProduct}
          />
          {/* <Route path="/configuration" render={(props) => <Configuration {...props} />}/>  */}
          <Route
            exact
            path="/create_basic_product"
            component={CreateBasicProduct}
          />
          <Route
            exact
            path="/create_parent_product"
            component={CreateParentProduct}
          />
          <Route
            exact
            path="/create_master_product"
            component={CreateMasterProduct}
          />
          <Route
            path="/edit_basic_product/:id"
            render={props => <EditBasicProduct {...props} />}
          />
          <Route
            path="/edit_parent_product/:id"
            render={props => <EditParentProduct {...props} />}
          />
          <Route
            path="/edit_master_product/:id"
            render={props => <EditMasterProduct {...props} />}
          />
          <Route
            path="/add_basic_product/:id"
            render={props => <EditBasicProduct {...props} />}
          />
          <Route
            path="/add_parent_product/:id"
            render={props => <EditParentProduct {...props} />}
          />
          <Route
            exact
            path="/product-configuration"
            component={ProductConfiguration}
          />
          <Route
            exact
            path="/shipstation-stores"
            component={ShipStationStoreList}
          />
          <Route exact path="/product-exports" component={ProductExports} />
          <Route exact path="/order-exports" component={OrderExports} />
          <Route
            exact
            path="/mc-settings"
            component={McSettingList}
          />

          <Redirect from="/" to="/" />
        </Switch>
      </SiteLayout>
    </Router>
    ,
  </Auth0Provider>,
  document.getElementById('root')
);
