import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import FormErrorText from './FormErrorText.jsx';

const FormInputField = (props) => {
    const { control, defaultValue, disabled, errors, errorText, name, placeholder, rules, type } = props;

    return (
        <div className="w-100">
            <Controller
                control={control}
                defaultValue={defaultValue}
                name={name}
                render={(field) => (
                    <input
                        className="form-control"
                        disabled={disabled}
                        name={name}
                        placeholder={placeholder}
                        type={type ? type : 'text'}
                        value={field.value || ''}
                        {...field}
                    />
                )}
                rules={rules}
            />
            {errors && errors[name] &&
                <FormErrorText
                    text={errors[name].message}
                />
            }
            {errorText && (
                <FormErrorText
                    text={errorText}
                />
            )}
        </div>
    );
};

FormInputField.defaultProps = {
    variant: 'outlined',
    defaultValue: '',
};

FormInputField.propTypes = {
    control: PropTypes.any,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    errors: PropTypes.any,
    errorText: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    rules: PropTypes.object,
    type: PropTypes.string,
};

export default FormInputField;
