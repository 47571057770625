import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PURCHASE_LIST_API } from "../configurations/configApi";
import { fetchUrl } from "../apiActions/action";
import { Modal, Backdrop } from '@material-ui/core/';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth : "1200px",
        position : "relative"
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        textAlign: 'right',
        width: '70px',
        marginTop: '.5rem'
    }
}));


const ViewPurchases = ({ productIds, handleClose }) => {
    const classes = useStyles();

    const [purchases, setPurchases] = useState([]);
    useEffect(() => {
        if (productIds.length) {
            fetchUrl(`${PURCHASE_LIST_API}?product_id=${productIds[0]}`, ({ status, response}) => {
                setPurchases(response.data);
            });
        }

    }, [productIds]);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Cost</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>DateTime</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                purchases.map(row => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell>{row.cost}</TableCell>
                                        <TableCell>{row.quantity}</TableCell>
                                        <TableCell>{new Date(row.created_on).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
            </Table>
                </div>
            </Modal>
        </>
    )
}

export default ViewPurchases;
