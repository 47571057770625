import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ORDER_CREATE_API_URL } from "../configurations/configApi";
import { apiPost } from "../apiActions/action";
import {Modal, Grid, Backdrop, Typography} from '@material-ui/core/';
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {FormItem} from "../components/FormFields";

const marketplaceOptions = localStorage.getItem("marketplaces_list") ? JSON.parse(localStorage.getItem("marketplaces_list")) : [];

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width : '800px',
        maxHeight: '800px',
        overflowY: 'scroll',
        position : "relative"
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

const invoiceForm = [
    {
        key: "",
        title: "Basic Info",
        items: [
            {
                name: "order_id",
                label: "Order ID",
                rules: {
                    required: "Order ID is required"
                }
            },
            {
                name: "order_time",
                label: "Order Time",
                type: "datetime-local",
                rules: {
                    required: "Order Time is required"
                }
            },
            {
                name: "order_number",
                label: "Order Number",
            },
            {
                name: "order_status",
                label: "Order Status",
                component: "select",
                items: [
                    {
                        label: "Open",
                        value: "Open"
                    },
                    {
                        label: "Acknowledged",
                        value: "Acknowledged"
                    },
                    {
                        label: "Canceled",
                        value: "Canceled"
                    },
                    {
                        label: "Complete",
                        value: "Complete"
                    },
                    {
                        label: "Shipped",
                        value: "Shipped"
                    }
                ],
                defaultValue: "Open"
            },
            {
                name: "marketplace",
                label: "MarketPlace",
                component: "select",
                items: marketplaceOptions.map(({ id, name }) => ({ label: name, value: id})),
                defaultValue: marketplaceOptions.length ? marketplaceOptions[0].id : null
            },
            {
                name: "cost",
                label: "Cost",
                type: "number",
                rules: {
                    required: "Cost is required"
                }
            },
            {
                name: "qty",
                label: "Quantity",
                type: "number",
                rules: {
                    required: "Quantity is required"
                }
            },
            {
                name: "amount",
                label: "Amount",
                type: "number",
                rules: {
                    required: "Amount is required"
                }
            },
            {
                name: "discount",
                label: "Discount",
                type: "number",
                defaultValue: 0
            },
            {
                name: "tax",
                label: "Tax",
                type: "number",
                defaultValue: 0
            },
            {
                name: "shipping_fee",
                label: "Shipping Fee",
                type: "number",
                defaultValue: 0
            },
        ]
    },
    {
        key: "ship_to_info",
        title: "Shipping",
        items: [
            {
                name: "firstName",
                label: "First Name",
                rules: {
                    required: "First Name is required"
                }
            },
            {
                name: "lastName",
                label: "Last Name",
                rules: {
                    required: "Last Name is required"
                }
            },
            {
                name: "companyName",
                label: "Company Name",
            },
            {
                name: "city",
                label: "City",
            },
            {
                name: "addressLine1",
                label: "Address",
            },
            {
                name: "emailAddress",
                label: "Email",
            },
            {
                name: "daytimePhone",
                label: "Day Time Phone",
            },
            {
                name: "eveningPhone",
                label: "Evening Time Phone",
            }
        ]
    },
    {
        key: "ship_to_bill_info",
        title: "Billing",
        items: [
            {
                name: "firstName",
                label: "First Name",
                rules: {
                    required: "First Name is required"
                }
            },
            {
                name: "lastName",
                label: "Last Name",
                rules: {
                    required: "Last Name is required"
                }
            },
            {
                name: "companyName",
                label: "Company Name",
            },
            {
                name: "city",
                label: "City",
            },
            {
                name: "addressLine1",
                label: "Address",
            },
            {
                name: "emailAddress",
                label: "Email",
            },
            {
                name: "daytimePhone",
                label: "Day Time Phone",
            },
            {
                name: "eveningPhone",
                label: "Evening Time Phone",
            }
        ]
    },
    {
        key: "waybill",
        title: "WayBills",
        items: [
            {
                name: "trackingNo",
                label: "Tracking Number",
            },
            {
                name: "shipLabelValue",
                label: "Ship label value",
            },
        ]
    }
]

const CreateInvoice = ({ productIds, handleClose }) => {
    const classes = useStyles();
    const { control,  handleSubmit, errors } = useForm();
    const onSubmit = (formData) => {
        const data = {
            ...formData,
            product_id: productIds[0]
        }
        apiPost(ORDER_CREATE_API_URL, data, ({ status }) => {
            handleClose(status);
        });
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} className={classes.paper}>
                    {invoiceForm.map(({ key, title, items }) => (
                        <div key={key}>
                            <Typography variant="subtitle1" gutterBottom>
                                {title}
                            </Typography>
                            <Grid container spacing={2}>
                                {items.map(({
                                        component,
                                        type,
                                        name,
                                        label,
                                        rules,
                                        ...rest
                                    }) => (
                                    <Grid item xs={4} key={key ? `${key}.${name}` : name}>
                                        <FormItem
                                            component={component || 'input'}
                                            type={type || 'text'}
                                            name={key ? `${key}.${name}` : name}
                                            label={rules?.required ? `${label}*`: label}
                                            control={control}
                                            errorText={key ? errors[key]?.[name]?.message : errors[name]?.message}
                                            rules={rules}
                                            {...rest}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ))}

                    <Grid container justify="flex-end" spacing={1}>
                        <Grid item>
                            <Button onClick={() => handleClose()}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" type="submit">Create</Button>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </>
    )
}

export default CreateInvoice;
