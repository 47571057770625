import React  from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import FormErrorText from './FormErrorText.jsx';

const FormSelectField = (props) => {
    const { control, defaultValue, disabled, errors, errorText, items, name, placeholder, rules, valueKey, labelKey } = props;

    return (
        <div className="w-100">
            <Controller
                control={control}
                defaultValue={defaultValue}
                name={name}
                render={(field) => (
                    <select
                        {...field}
                        disabled={disabled}
                        className="form-control"
                        placeholder={placeholder}
                        value={field.value || ''}
                    >
                        {items.map((item, i) => (
                            <option key={i} value={item[valueKey]}>{item[labelKey]}</option>
                        ))}
                    </select>
                )}
                rules={rules}
            />
            {errors && errors[name] &&
                <FormErrorText
                    text={errors[name].message}
                />
            }
            {errorText && (
                <FormErrorText
                    text={errorText}
                />
            )}
        </div>
    );
};

FormSelectField.defaultProps = {
    labelKey: 'label',
    variant: 'outlined',
    valueKey: 'value',
};

FormSelectField.propTypes = {
    control: PropTypes.any,
    disabled: PropTypes.bool,
    errors: PropTypes.any,
    errorText: PropTypes.string,
    items: PropTypes.array,
    labelKey: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    rules: PropTypes.object,
    valueKey: PropTypes.string,
};

export default FormSelectField;
