import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import MenuList from "../../../gridComponents/MenuList";
import Permission from "../../../permission/Permission";
import { handleError } from "../../FunctionalComponents/ErrorHandle";
import errorRedirect from "../../FunctionalComponents/ErrorRedirect";
import { ORDER_EXPORT } from "../../../configurations/configApi";

const columnDefs = [
    {
        headerName: "Query Params",
        field: "query_param",
        cellRenderer: (params) => {
            const { value } = params;

            return (
                value ||
                '<img src="https://www.ag-grid.com/example-assets/loading.gif" />'
            );
        },
    },
    {
        headerName: "Status",
        field: "status",
    },
    {
        headerName: "CSV File",
        field: "csv_file",
        cellRenderer: (params) => {
            const { value, data } = params;

            return value
                ? `<a href="${value}" target="_blank" rel="noopener">Download</a>`
                : data && data.status === "IN PROGRESS"
                    ? "Generating File..."
                    : data
                        ? "No File"
                        : "";
        },
    },
    {
        headerName: "User",
        field: "user",
    },
    {
        headerName: "Created On",
        field: "created_on",
    },
];

const OrderExports = () => {
    const createServerSideDatasource = (gridParams) => {
        return {
            getRows: (params) => {
                let page = params.endRow / 50;

                const headers = {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                };

                Axios(`${ORDER_EXPORT}?page=${page}`, {
                    method: "get",
                    headers,
                })
                    .then((response) => {
                        const { results } = response.data;
                        // console.log(results);
                        console.log(results.length);
                        if (results.length > 0) {
                            let lastRow = (results, request) => {
                                if (!results) return undefined;
                                var currentLastRow = request.startRow + results.length;

                                return currentLastRow < request.endRow
                                    ? currentLastRow
                                    : undefined;
                            };
                            params.successCallback(results, lastRow(results, params));
                        } else {
                            params.successCallback([{ columnNameField: "No Data" }], 0);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (
                            error.response &&
                            error.response.status &&
                            error.response.status === 401
                        ) {
                            handleError(error.response);
                        } else errorRedirect(error.response);
                    });
            },
        };
    };

    const onGridReady = (params) => {
        // this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        // this.setState({ params: params });
        // // this.autoSizeAll(false);

        const datasource = createServerSideDatasource(params);
        params.api.setDatasource(datasource);
    };

    return (
        <>
            <Permission />
            <MenuList />
            <div className="heading">
                <h1 id="catalog-title">Order Exports</h1>
                <Link
                    to="/product-exports"
                    className="d-inline-block position-relative ml-3"
                    style={{ zIndex: 9 }}
                >
                    <h5 style={{ color: '#3c4858' }}>Product Exports</h5>
                </Link>
            </div>
            <div
                id="myGrid"
                style={{
                    height: "100%",
                    width: "100%",
                }}
                className="ag-theme-alpine catalogs"
            >
                <AgGridReact
                    rowModelType="infinite"
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    cacheBlockSize={50}
                />
            </div>
        </>
    );
};

export default OrderExports;