import React, { Component } from "react";

export default class OrderListStatusBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totals: {
          total_platform_amount: 0,
          total_platform_shipping_fee: 0,
          total_cost: 0,
          total_roi: 0,
          total_profit_margin: 0,
      }
    };

    setTimeout(() => {
        this.setState({
            totals: this.props.context.componentParent.state.totals
        })
    }, 3000)
  }

  render() {
    const {
      total_platform_amount,
      total_platform_shipping_fee,
      total_cost,
      total_roi,
      total_profit_margin,
    } = this.state.totals;
    return (
        <div className="ag-status-name-value">
            <span className="component">Total Platform Amount: </span>
            <span className="ag-status-name-value-value">{total_platform_amount?.toLocaleString()}   </span>
            <span className="component">Total Platform Shipping Fee: </span>
            <span className="ag-status-name-value-value">{total_platform_shipping_fee?.toLocaleString()}   </span>
            <span className="component">Total Cost: </span>
            <span className="ag-status-name-value-value">{total_cost?.toLocaleString()}   </span>
            <span className="component">Total ROI: </span>
            <span className="ag-status-name-value-value">{total_roi?.toLocaleString()} %   </span>
            <span className="component">Total P&L Margin: </span>
            <span className="ag-status-name-value-value">{total_profit_margin?.toLocaleString()} %   </span>
        </div>
    );
  }
}
