import React from 'react';
import PropTypes from 'prop-types';

const components = {};

export function registerComponent(name, Component) {
    components[name] = Component;
}

export default function FormField(props) {
    const { component: name, ...rest } = props;
    const C = components[name];
    return C ? <C {...rest} /> : null;
}

FormField.propTypes = {
    component: PropTypes.string,
    handleChange: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
};
