import React, { Component } from "react";
import { Link } from "react-router-dom";
import ConfigurationPage from "views/Pages/ConfigurationPage";

export default class MenuList extends Component {
  render() {
    const { pathname } = window.location;

    return (
      <div className="subMenu">
        <ul>
          <li>
            <Link to="/" className={pathname === "/" ? "selected" : ""}>
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/catalogs"
              className={pathname === "/catalogs" ? "selected" : ""}
            >
              Catalogs
            </Link>
          </li>
          {/* <li>
            <Link
              to="/catalogs"
              className={
                pathname === "/catalogs" ? "selected" : ""
              }
            >
              Catalogs
            </Link>
          </li> */}
          <li>
            <Link
              to="/orders"
              className={pathname === "/orders" ? "selected" : ""}
            >
              Orders
            </Link>
          </li>
          <li>
            <Link
              to="/configuration"
              className={pathname === "/configuration" ? "selected" : ""}
            >
              Configuration
            </Link>
          </li>
          <li>
            <Link to="/rule" className={pathname === "/rule" ? "selected" : ""}>
              Rule
            </Link>
          </li>
          <li>
            <Link
              to="/product-exports"
              className={pathname === "/product-exports" || pathname === "/order-exports" ? "selected" : ""}
            >
              Exports
            </Link>
          </li>
          {/* <li>
              <a href="#">Import a File</a>
            </li>
            <li>
              <a href="#">Imports/Exports</a>
            </li>
            <li>
              <a href="#">Images</a>
            </li>
            <li>
              <a href="#">Add Partner</a>
            </li> */}
        </ul>
        {/* <span className="search">
            <input type="text" placeholder="Search" />
          </span> */}
      </div>
    );
  }
}
