import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PURCHASE_LIST_API } from "../configurations/configApi";
import { apiPost } from "../apiActions/action";
import { Modal, Backdrop } from '@material-ui/core/';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth : "1200px",
        position : "relative"
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        textAlign: 'right',
        width: '70px',
        marginTop: '.5rem'
    }
}));


const CreatePurchase = ({ productIds, handleClose }) => {
    const classes = useStyles();

    const [cost, setCost] = useState();
    const [quantity, setQuantity] = useState();
    const handleCreatePurchase = () => {
        if (!cost || !quantity) {
            return;
        }
        const productId = productIds[0];
        if (!productId) {
            return;
        }
        apiPost(PURCHASE_LIST_API, {
            product: productId,
            cost,
            quantity
        }, ({ status, response }) => {
            handleClose(status);
        })
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <div className={`${classes.center} input-group mb-2`}>
                        <label for="cost" className={classes.label}>Cost:&nbsp;</label>
                        <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                        </div>
                        <input
                            type="number"
                            step="0.01"
                            className="form-control text-right"
                            name="cost"
                            id="cost"
                            value={cost}
                            onChange={(e) => setCost(e.target.value)}
                        />
                        <br/>
                    </div>
                    <div className={`${classes.center} input-group mb-2`}>
                        <label for="quantity" className={classes.label}>Quantity:&nbsp;</label>
                        <input
                            type="number"
                            step="1"
                            className="form-control text-right"
                            name="quantity"
                            id="quantity"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        <br/>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button className="mr-2" onClick={() => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleCreatePurchase}>
                            Create
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CreatePurchase;
