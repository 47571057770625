import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";

import FormField, { registerComponent } from './FormField.jsx';
import FormInputField from './FormInputField.jsx';
import FormSelectField from './FormSelectField.jsx';

const useStyles = makeStyles((theme) => ({
    flexLabel: {
        marginRight: '8px',
        minWidth: '30%',
    },
}));

registerComponent('input', FormInputField);
registerComponent('select', FormSelectField);

const FormItem = (props) => {
    const { label, flex, ...others } = props;
    const styles = useStyles();

    return (
        <div className={clsx('w-100 mb-2', flex && 'd-flex align-items-center justify-content-between', props?.classes?.root && props.classes.root)}>
            {!!label && (
                <p className={clsx('m-0', flex && styles.flexLabel)}>{label}</p>
            )}
            <FormField
                {...others}
            />
        </div>
    );
};

FormItem.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    flex: PropTypes.bool,
};

export default FormItem;
