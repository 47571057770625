import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const FormErrorText = props => {
    return (
        <p className={clsx('mt-1 text-danger', props.className)} style={{ fontSize: 12 }}>
            {props.text}
        </p>
    );
};

FormErrorText.propTypes = {
    className: PropTypes.any,
    text: PropTypes.string
};

export default FormErrorText;
